<template>
  <div>
    <error-screen title="Unable to track parcels" subtitle="Please ensure correct tracking ids are uploaded" v-if="error"/>
    <div v-if="parcels">
      <div class="py-6 bg-white border-t border-gray-200 shadow-sm">
        <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div class="md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
                <h2 class="text-2xl leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  <span class="font-bold">Bulk Search Results based on Carrier and Tracking Ids</span> <span>({{ $route.params.carrierId }})</span>
                </h2>
              </div>
              <div class="flex mt-4 md:mt-0 md:ml-4">
                <router-link :to="{ name: 'home' }">
                    <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-gray-300 rounded-md shadow-sm bg-pol-grey hover:bg-gray-900">
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                      </svg>
                      Back to search
                    </button>
                  </router-link>
              </div>
            </div>
        </div>
      </div>
      <main>
        <div class="max-w-6xl mx-auto mt-8 sm:px-6 lg:px-8">
          <div class="flex flex-col">
            <div>
              <vue-json-to-csv :json-data="parcels"
                :csv-title="csvFileTitle" :show-labels="true">
                <button class="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                  Download CSV
                </button>
              </vue-json-to-csv>
            </div>
            <div class="-my-6 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="float-right w-full lg:max-w-md sm:px-6 lg:px-8">
                <label for="search" class="sr-only">Search</label>
                <div class="relative text-gray-600 bg-white rounded-lg">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <input v-model="filter" id="search" class="block w-full py-2 pl-10 pr-3 leading-5 text-gray-600 placeholder-gray-600 border border-transparent rounded-md bg-opacity-20 focus:text-gray-900 focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="Filter on tracking id / contact name / status" type="search" name="search">
                </div>
              </div>
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="table-header">Carrier</th>
                        <th scope="col" class="table-header">Branch ID</th>
                        <th scope="col" class="table-header">Tracking ID</th>
                        <th scope="col" class="table-header">Last Updated</th>
                        <th scope="col" class="table-header">Contact Name</th>
                        <th scope="col" class="table-header">Status</th>
                        <th scope="col" class="table-header">Updates</th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="parcel in filteredParcels" :key="parcel.hk + '-' + parcel.sk">
                        <td class="px-6 py-2 whitespace-nowrap">
                          <div class="flex items-center">
                            <div class="flex-shrink-0 w-10 h-10">
                              <img class="w-10 h-10 rounded-md" :src="getIconUrl(parcel.carrierId)" alt="Carrier Logo">
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-2 text-sm whitespace-nowrap">
                          {{ parcel.branchId }}
                        </td>
                        <td class="px-6 py-2 text-sm whitespace-nowrap">
                          {{ parcel.trackingId }}
                        </td>
                        <td class="px-6 py-2 text-sm whitespace-nowrap">
                          {{ parcel.createdAt | moment('MMMM Do YYYY')}}
                        </td>
                        <td class="px-6 py-2 text-sm text-black truncate whitespace-nowrap">
                          {{ parcel.contactName }}
                        </td>
                        <td class="px-6 py-2 text-sm text-black whitespace-nowrap">
                          <pre>{{ parcel.status }}</pre>
                        </td>
                        <td class="px-6 py-2 text-sm text-black whitespace-nowrap">
                          {{ parcel.Version }}
                        </td>
                        <td class="px-6 py-2 text-sm font-medium text-right whitespace-nowrap">
                          <router-link :to="{ name: 'track', params: { carrierId: parcel.carrierId, trackingId: parcel.trackingId }}">
                            <button type="button" class="inline-flex items-center p-1 text-sm font-medium text-gray-700 bg-white rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-gray-200">
                              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </button>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                  <div class="float-right py-4 text-xs"><strong>Total results: </strong>{{ parcels.length }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import ErrorScreen from '../components/ErrorScreen.vue'
import VueJsonToCsv from 'vue-json-to-csv'

export default {
  name: 'BulkTracking',
  components: {
    ErrorScreen,
    'vue-json-to-csv': VueJsonToCsv
  },
  metaInfo: {
    title: 'Bulk Tracking Parcels based on Tracking Id'
  },
  data () {
    return {
      error: false,
      parcels: null,
      filter: '',
      csvFileTitle: 'PUDO_Parcels_Tracking_' + Date.now()
    }
  },
  async created () {
    const loader = this.$loading.show()

    try {
      const user = await Auth.currentAuthenticatedUser()
      this.$http.defaults.headers.common.Authorization = user.signInUserSession.idToken.jwtToken
      const trackingIdsObj = { trackingId: JSON.parse(this.$store.state.transferredData.transferredData) }
      const apiPath = `${process.env.VUE_APP_API_BASE}/parcels/${this.$route.params.carrierId}`
      const result = await this.$http.post(apiPath, trackingIdsObj)
      this.parcels = result.data.parcels
    } catch (error) {
      this.error = true
    }

    // hide loader
    loader.hide()
  },
  computed: {
    filteredParcels () {
      return this.parcels.filter(parcel => {
        const trackingId = parcel.trackingId.toLowerCase()
        const contactName = parcel.contactName ? parcel.contactName.toLowerCase() : ''
        const status = parcel.status.toLowerCase()
        const searchTerm = this.filter.toLowerCase()
        return trackingId.includes(searchTerm) || contactName.includes(searchTerm) || status.includes(searchTerm)
      })
    }
  }
}
</script>
<style scoped>
  .table-header {
    @apply px-6 py-3 text-xs font-bold tracking-wider text-left text-black uppercase;
  }
</style>
